<template>
    <div class='camp__wrapper' :class="{ 'camp__wrapper--active': active }" @click="active = ! active">
        <div class="camp__inner">
            <div class="camp__front">
                <div :class="`camp__image camp__${nameSlug}`">
                    <div class="camp__name camp__name-front">{{ value.KampterreinNaam }}</div>
                    <div class="camp__info-icon">
                        <span class="material-icons">info</span>
                    </div>
                </div>
                <div class="camp__description">
                    <h3 class="camp__number">Kamp {{ value.Nummer }}</h3>
                        <div class='camp__detail-grid'>
                        <div class="camp__detail">
                            <span class="material-icons camp__icon">face</span>
                            <span>{{ value.LeeftijdVan }} tot {{ value.LeeftijdTot }} jaar</span>
                        </div>
                        <div class="camp__detail" style="margin-right: auto">
                            <span class="material-icons camp__icon">euro</span>
                            <span>{{ formatCurrency(value.Prijs) }}</span>
                        </div>
                        <div class="camp__detail" style="grid-column: 1 / 3">
                            <span class="material-icons camp__icon">calendar_month</span>
                            <span>{{ formatDate(value.BeginDatum) }} - {{ formatDate(value.EindDatum) }}</span>
                        </div>
                    </div>

                    <a v-if="siteStatus === 'Open'" href="https://mijn.lckv.nl" target="_blank" class="button">Direct inschrijven</a>
                    <a v-else :href="url" target="_blank" class="button">Meer informatie</a>
                </div>
            </div>
            <div :class="`camp__back camp__${nameSlug}`">
                <div class="back__content">
                    <div class="camp__info-icon">
                        <span class="material-icons">close</span>
                    </div>
                    <div class="camp__name">{{ value.KampterreinNaam }}</div>
                    <div class="camp__info" v-if="info[nameSlug]">{{ info[nameSlug] }}</div>
                    <div class="camp__info" v-else>Kijk op www.LCKV.nl voor meer informatie over dit terrein!</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .camp {
        &__wrapper {
            transition-duration: .3s;
            perspective: 1000px;

            &:not(&--active):hover {
                transform: scale(1.05);
            }

            &--active .camp__inner {
                transform: scale(1.05) rotateY(180deg);
            }
        }

        &__inner {
            position: relative;
            width: 100%;
            height: 100%;
            transition: transform 0.8s;
            transform-style: preserve-3d;
        }

        &__front, &__back {
            -webkit-backface-visibility: hidden; /* Safari */
            backface-visibility: hidden;
        }

        &__front {
            border: 1px solid #72665a;
            background: #f9f2eb;
            color: rgb(85, 85, 85);
        }

        &__back {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform: rotateY(180deg);
            background-color: #f53c05;
            padding: 1em 5px 1em 1em;
            box-sizing: border-box;
            overflow-y: auto;

            &.camp__bladel       { background-color: #f53c05;}
            &.camp__chaam        { background-color: #05c8d9;}
            &.camp__diever       { background-color: #ffc300;}
            &.camp__terschelling { background-color: #3FA543;}
            &.camp__haute-roche  { background-color: #f53c05;}
            &.camp__lieler       { background-color: #05c8d9;}
            &.camp__viroinval    { background-color: #ffc300;}
            &.camp__monschau     { background-color: #3FA543;}
        }

        &__image {
            background-image: 
                linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 0.4)),
                url('../assets/images/zwemmen.png');
            background-size: cover;
            background-position: center center;
            width: 100%;
            padding-bottom: 45%;
            position: relative;
            transition-duration: 0.3s;
            overflow: hidden;

            &.camp__bladel {
                background-image: 
                    linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 0.4)),
                    url('../assets/images/bladel.jpg');
            }
            &.camp__chaam {
                background-image: 
                    linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 0.4)),
                    url('../assets/images/chaam.png');
            }
            &.camp__diever {
                background-image: 
                    linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 0.4)),
                    url('../assets/images/diever.jpg');
            }
            &.camp__terschelling {
                background-image: 
                    linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 0.4)),
                    url('../assets/images/terschelling.jpg');
            }
            &.camp__haute-roche {
                background-image: 
                    linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 0.4)),
                    url('../assets/images/haute-roche.jpg');
            }
            &.camp__lieler {
                background-image: 
                    linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 0.4)),
                    url('../assets/images/lieler.jpg');
            }
            &.camp__viroinval {
                background-image: 
                    linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 0.4)),
                    url('../assets/images/viroinval.jpg');
            }
            &.camp__monschau {
                background-image: 
                    linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 0.4)),
                    url('../assets/images/monschau.jpg');
            }
        }

        &__name {
            color: #fff;
            font-weight: bold;

            &-front {
                position: absolute;
                bottom: 0.5em;
                left: 0.5em;
                transform: rotate(-5deg);
            }

            @media screen and (min-width: 769px) {
                font-size: 3em;
            }
            @media screen and (max-width: 768px) {
                font-size: 5em;
            }
        }

        &__number {
            color: #f53c05;
            margin: 0 0 0.5em 0;
            font-size: 1.25em;
            font-weight: bold;
        }

        &__description {
            padding: .5em 1em;
            line-height: 1.25em;

            @media screen and (min-width: 1201px) {
                font-size: 1.25em;
            }
            @media screen and (min-width: 769px) and (max-width: 1200px) {
                font-size: 1.5em;
            }
            @media screen and (max-width: 768px) {
                font-size: 2em;
            }
        }

        &__info {
            color: #ffffff;
            line-height: 1.125em;
            margin-top: 10px;

            @media screen and (min-width: 1201px) {
                font-size: 1.25em;
            }
            @media screen and (min-width: 769px) and (max-width: 1200px) {
                font-size: 1.5em;
            }
            @media screen and (max-width: 768px) {
                font-size: 1.875em;
            }
        }

        &__detail-grid {
            display: grid;
            grid-template-columns: calc(65% - 10px) 35%;
            gap: 10px;
        }

        &__detail {
            display: flex;
            align-items: center;;
        }

        &__icon {
            margin-right: 10px;
        }

        &__info-icon {
            color: #ffffff;
            position: absolute;
            top: 0.5em;
            right: 0.5em;
            margin: 0;
        }
    }

    .button {
        background: none;
        border: 4px solid rgb(85, 85, 85);
        padding: 5px 10px;
        color: rgb(85, 85, 85);
        font-size: 1em;
        font-family: 'Gentona',sans-serif;
        font-weight: 600;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        width: 100%;
        display: block;
        box-sizing: border-box;
        margin-top: 20px;

        &:hover {
            text-decoration: underline;
        }
    }

    .back__content {
        width: 100%;
        height: 100%;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 6px;
            background: rgba(255,255,255,0.4);
        }

        &:hover {
            &::-webkit-scrollbar-thumb {
                background: rgba(255,255,255,0.75);
            }
        }
    }
</style>

<script>
export default {
    name: 'LckvCamp',

    props: {
        siteStatus: { type: String, default: 'Open' },
        value:      { type: Object, required: true }
    },

    data() {
        return {
            active: false,
            info: {
                bladel:        "Als je aankomt op dit kampterrein weet je het meteen. Je moet heel goed uitkijken voor de bomen in het donker. Dit kampterrein ligt namelijk middenin de bossen. Hier zie je letterlijk niet de bomen door het bos. Maar, het maakt de nachtwandeling richting de tent toch een stuk avontuurlijker. Daarnaast geeft het de optie om te genieten van de frisse ochtenddauw waarin je met slaaptenue nog aan die geweldige ochtendgym staat te rocken.",
                chaam:         "Midden in de Brabantse bossen van Chaam zie je de vertrouwde LCKV-tenten staan. Afgezonderd van de buitenwereld kan jouw fantastische week hier beginnen. De omgeving is prachtig: je loopt hier zo de bossen in en op slechts een paar minuten lopen is er een heerlijk verkoelend meer, met een eigen strand! Wat is er nou beter dan na een dagtocht of een leuk bosspel nog even te chillen in het verkoelende water? Als dat geen heerlijke week wordt!",
                diever:        "De ideale kamplocatie! Ook wel bekend als kampterrein ‘De Roege Stobbe’. Diever (Drenthe) is het terrein waar je sowieso moet zijn geweest. Middenin een enorm bosgebied en ver van de bewoonde wereld. Ideaal voor leuke en spannende bosspellen.",
                terschelling:  "Een week op kamp naar Terschelling is absoluut anders dan alle andere kampen. Eerst met de bus naar Harlingen en vanaf daar met de boot naar het eiland. Als je op het dek van de boot staat en de zon brandt op je gezicht heb je meteen het ultieme vakantiegevoel: het begin van een week om nooit meer te vergeten.",
                'haute-roche': "Ben je klaar met de binnenlandse kampen en wil je je grenzen verleggen naar het buitenland? Ga dan voor het kampterrein Haute Roche; het perfecte kamp voor jou! Dit prachtige kampterrein van LCKV Jeugdvakanties ligt bij het kleine Belgische plaatsje Dourbes, vlakbij de grens met Frankrijk.",
                viroinval:     "Dit jaar hebben wij een nieuw kampterrein. Op de grens van België en Frankrijk ligt een enorm mooi kampterrein genaamd Viroinval. Dit kampterrein is omringd door een enorm bosgebied en ligt ver afgelegen van de bewoonde wereld. Vergeet je zwembroek niet, want er gaat een beekje langs het kampterrein. Heerlijk om even lekker af te koelen na een dag vol activiteiten.",
                lieler:        "Lieler biedt jou een heerlijke verfrissende beek, waar je lekker in kunt ronddobberen. Vergeet dus die opblaaskrokodil niet! Denk nou niet dat je een hele week in de rivier dobbert of in de zon ligt. Welnee! Een vet spel op de steile hellingen in de schitterende omgeving, een kanotocht, mountainbiken in de heuvels, abseilen, een spel dwars door Duitsland, België en Luxemburg en een echte spooktocht kunnen hier natuurlijk niet ontbreken.",
                monchou:       "Naast Viroinval hebben we nog een nieuw kampterrein: Monschau! Naast het idyllische dorpje Monschau in Duitsland, ligt in het natuurgebied de Eiffel dit nieuwe terrein. Er stroomt een beek om en door het terrein heen. Perfect om af te koelen en te dobberen om de drukke dag even te vergeten. Ook is er voldoende ruimte voor outdoor-activiteiten. Dit gaat een onvergetelijke week worden.",

            }
        };
    },

    computed: {
        nameSlug() {
            return this.value.KampterreinNaam.replace(" ", "-").toLowerCase()
        },

        url() {
            switch(this.value.KampterreinNaam.toLowerCase()) {
                case 'bladel':
                    return 'https://www.lckv.nl/kampen/bladel';
                case 'chaam':
                    return 'https://www.lckv.nl/kampen/chaam';
                case 'diever':
                    return 'https://www.lckv.nl/kampen/diever';
                case 'terschelling':
                    return 'https://www.lckv.nl/kampen/terschellin';
                case 'haute-roche':
                    return 'https://www.lckv.nl/kampen/haute-roche';
                case 'viroinval':
                    return 'https://www.lckv.nl/kampen/viroinval';
                case 'lieler':
                    return 'https://www.lckv.nl/kampen/lieler';
                case 'monchou':
                    return 'https://www.lckv.nl/kampen/monchou';
                default:
                    return 'https://www.lckv.nl/kampen';
            }
        }
    },

    methods: {
        formatDate(value) {
            let date = new Date(value);
            if (date) {
                return date.toLocaleDateString("nl-NL", { month: 'short', day: 'numeric' });
            }
            return '';
        },

        formatCurrency(value) {
            return Intl.NumberFormat('nl-NL', { style: "decimal", minimumFractionDigits: 2 }).format(value);
        },
    }
}
</script>
<template>
    <header id="header" class="header__video">
        <video playsinline autoplay muted loop>
            <source src="../assets/videos/LCKV-landing-slow.mp4" type="video/mp4">
        </video>
        <figure class="logo">
            <img src="../assets/images/logo.svg" alt="Logo LCKV Jeugdvakanties">
        </figure>
        <h1>Een week op vakantie zonder je ouders!</h1>
        <div class="buttons">
            <a :href="button.url" :target="button.target" class="button">{{ button.text }}</a>
        </div>
        <a href="#video" class="scrolldown__wrapper">
            <span></span><br>
            <div class="scrolldown__text">Meer informatie</div>
        </a>
    </header>
</template>

<style lang="scss" scoped>

header {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.scrolldown {
    &__wrapper {
        text-align: center;

        span {
            width: 24px;
            height: 24px;
            border-left: 1px solid #fff;
            border-bottom: 1px solid #fff;
            transform: rotate(-45deg);
            box-sizing: border-box;
            display: inline-block;
        }
    }

    &__text {
        color: #ffffff;
        display: inline-block;
        font-size: 1em;
        letter-spacing: 0.1em;
        text-decoration: none;
        transition: opacity 0.3s;
        text-align: center;
        margin-top: 15px;
    }
}

.header {
    &__video {
        height: 100vh;
        box-sizing: border-box;
        padding: 4em;

        &::after {
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            position: absolute;
            display: block;
            content: '';
            background: rgba(0, 0, 0, 0.5);
            z-index: -1;
        }

        * {
            z-index: 2;
        }

        video, iframe {
            position: fixed;
            z-index: -999;
            height: 100%;
            width: 177.77777778vh;
            min-width: 100%;
            min-height: 56.25vw;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .logo img {
            width: 22em;
        }

        h1 {
            color: #fff;
            font-size: 4.5em;
            font-weight: 800;
            text-align: center;
            text-transform: uppercase;
        }
    }
}

</style>

<script>

export default {
    name: 'LckvHeader',

    props: {
        siteStatus: { type: String, default: 'Open' },
        beforeDraw: { type: Boolean, default: true },
    },

    computed: {
        button() {
            if (this.siteStatus !== 'Open') {
                return { 'text': 'Meer informatie',    'url': 'https://www.lckv.nl/',  'target': '_parent' }
            }
            return this.beforeDraw
                ? { 'text': 'Inschrijven voor de loting', 'url': 'https://mijn.lckv.nl/', 'target': '_blank' }
                : { 'text': 'Direct inschrijven', 'url': 'https://mijn.lckv.nl/', 'target': '_blank' }
            ;
        }
    },
};
</script>

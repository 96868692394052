<template>
    <section id="about" class="page__section about__section">
        <h2 class="section__title">Wat is LCKV Jeugdvakanties?</h2>
        <div class="section__text">
            Kamperen, spannende bosspelen, feesten, survival, adventure, nieuwe vrienden en zeven dagen lol. Zomaar een greep uit de ingrediënten van een week kamp met LCKV Jeugdvakanties. In Nederland of over de grens? Zeg het maar. We hebben het allemaal! Kijk op <a href='https://www.lckv.nl/' target='_blank'>www.LCKV.nl</a> om alles te weten te komen over LCKV Jeugdvakanties. Nou ja, alles? Daarvoor moet je het echt meemaken.
        </div>
        <div class="buttons">
            <a href="https://folder.lckv.nl" target="_blank" class="button">Bekijk de folder</a>
            <a v-if="siteStatus === 'Open'" href="https://mijn.lckv.nl/" target="_blank" class="button">Direct inschrijven</a>
            <a href="https://www.lckv.nl" target="_blank" class="button">Meer informatie</a>
        </div>
    </section>    
</template>

<style lang="scss" scoped>
.about {
        &__section {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            gap: 50px;
            background-image: url("../assets/images/wiltz.jpg");
            background-size: cover;
            background-position: center center;
            background-attachment: fixed;
            z-index: 0;
            overflow-x: hidden;

            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background:rgba(0, 0, 0, 0.5);
                z-index: -1;
            }

            .section__title,
            .section__text,
            .buttons {
                opacity: 0; // is set to 1 in fadeIn animation
                color: #ffffff;

                &.animation {
                    animation-name: fadeInSlideLeft;
                    animation-duration: 1s;
                    animation-timing-function: ease-in-out;
                    animation-iteration-count: 1;
                    animation-direction: normal;
                    animation-fill-mode: forwards;
                }
            }

            .section__title {
                animation-delay: .2s;
            }

            .section__text {
                animation-delay: .4s;
            }

            .buttons {
                animation-delay: .6s;
            }
        }
    }

    @keyframes fadeInSlideLeft {
        from { opacity: 0; transform: translateX(25px); }
        to   { opacity: 1; transform: translateX(0); }
    }
</style>

<script>
export default {
    name: 'LckvAbout',

    props: {
        siteStatus: { type: String, default: 'Open' }
    },

    mounted() {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animation');
                }
            });
        });
        
        observer.observe(document.querySelector('.about__section .section__title'));
        observer.observe(document.querySelector('.about__section .section__text'));
        observer.observe(document.querySelector('.about__section .buttons'));
    }
}
</script>
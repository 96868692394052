<template>
    <footer>
        <a href="https://www.tiktok.com/@lckvjeugdvakanties?v=1651482607" target="_blank" class="social__link">
            <img src="../assets/images/socials/tiktok.svg" class="social__logo" alt="Volg LCKV Jeugdvakanties op TikTok!">
            <span class="social__text">@lckvjeugdvakanties</span>
        </a>
        <a href="https://www.instagram.com/lckvjeugdvakanties" target="_blank" class="social__link">
            <img src="../assets/images/socials/instagram.svg?v=1651482607" class="social__logo" alt="Volg LCKV Jeugdvakanties op Instagram!">
            <span class="social__text">@lckvjeugdvakanties</span>
        </a>
        <a href="https://www.facebook.com/lckvjeugdvakanties" target="_blank" class="social__link">
            <img src="../assets/images/socials/facebook.svg?v=1651482607" class="social__logo" alt="Volg LCKV Jeugdvakanties op Facebook!">
            <span class="social__text">/lckvjeugdvakanties</span>
        </a>
    </footer>
</template>

<style lang="scss" scoped>
    footer {
        background: #72665a;
        min-height: 150px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 4em;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            padding: 4em;
        }
        
        .social {
            &__link {
                display: flex;
                align-items: center;
                gap: 10px;
                text-decoration: none;

                @media screen and (max-width: 768px) {
                    width: 180px;
                }
            }
            &__logo {
                width: 40px;
                height: 40px;
            }
            &__text {
                font-size: 1.5em;
                color: #ffffff;

                @media screen and (min-width: 769px) and (max-width: 1024px) {
                    font-size: 1.5em;
                }
            }
        }
    }
</style>

<script>
export default {
    name: 'LckvFooter'
}
</script>
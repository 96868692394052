<template>
    <section id="video" class="page__section video__section">
        <h2 class="section__title">Sfeerimpressie</h2>
        <div class="section__text">
            Een week vol met spellen, activiteiten en belevenissen is natuurlijk lastig in woorden te omschrijven.
            Bekijk daarom hieronder in een paar korte filmpjes hoe een week op kamp er ongeveer uit ziet.
        </div>
        <div class="video__grid">
            <div class="video" @click="openPlayerDialog('spellen')">
                <img src="../assets/images/play-button.svg" class="video__play-button" alt="play video">
            </div>
            <div class="video" @click="openPlayerDialog('zwemmen')">
                <img src="../assets/images/play-button.svg" class="video__play-button" alt="play video">
            </div>
            <div class="video" @click="openPlayerDialog('kampvuur')">
                <img src="../assets/images/play-button.svg" class="video__play-button" alt="play video">
            </div>
        </div>
        <dialog open v-if="dialog" class="video-dialog" @click="closePlayerDialog">
            <div class="dialog__content">
                <iframe :src="`https://www.youtube-nocookie.com/embed/${dialog}?autoplay=1&disablekb=1&playsinline=1`"></iframe>
            </div>
        </dialog>
    </section>
</template>

<style lang="scss" scoped>
    .video {
        &__section {
            display: flex;
            min-height: 100vh;
            flex-direction: column;
            justify-content: space-around;
            gap: 50px;
            background-image: url('../assets/images/boom.svg');
            background-repeat: no-repeat;
            background-attachment: fixed;
            
            @media screen and (min-width: 769px) {
                background-position: right -50vh top 10vh;
                background-size: 120vh;
            }
            @media screen and (max-width: 768px) {
                background-position: right -30vh top 40vh;
                background-size: 65vh;
            }

            .section__title,
            .video__grid,
            .section__text {
                opacity: 0; // is set to 1 in fadeIn animation

                &.animation {
                    animation-name: fadeInSlideUp;
                    animation-duration: 1s;
                    animation-timing-function: ease-in-out;
                    animation-iteration-count: 1;
                    animation-direction: normal;
                    animation-fill-mode: forwards;
                }
            }
            .section__text {
                animation-delay: .2s;
            }

            .video__grid {
                animation-delay: .4s;
            }
        }

        &__grid {
            margin: auto;
            max-width: 90vw;
            display: grid;
            gap: 5px;
            background: rgba(255, 255, 255, 0.5);
            padding: 5px;

            @media screen and (min-width: 1201px) {
                grid-template-columns: repeat(3, 1fr);
                width: 75%;
            }

            @media screen and (min-width: 1025px) and (max-width: 1200px) {
                grid-template-columns: repeat(3, 1fr);
                width: 100%;
            }

            @media screen and (min-width: 769px) and (max-width: 1024px) {
                grid-template-columns: repeat(2, 1fr);
                width: 100%;
            }

            @media screen and (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
                width: 100%;
            }
            .video {
                background-size: cover;
                width: 100%;
                padding-bottom: 56.25%;
                position: relative;
                transition-duration: 0.3s;
                overflow: hidden;

                &:nth-child(1) { background-image: url('../assets/images/spellen.png'); }
                &:nth-child(2) { background-image: url('../assets/images/zwemmen.png'); }
                &:nth-child(3) { background-image: url('../assets/images/kampvuur.png'); }

                &::before {
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    position: absolute;
                    display: block;
                    content: '';
                    background: rgba(0, 0, 0, 0.25);
                    transition-duration: 0.3s;
                    background-size: cover;
                    transform-origin: center;
                    z-index: 1;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: inherit;
                    transition: inherit;
                    z-index: 0;
                }

                &:hover  {
                    &::before {
                        background: rgba(0, 0, 0, 0.5);
                    }

                    &:nth-child(1)::after { transform: scale(1.5) rotate(20deg);    }
                    &:nth-child(2)::after { transform: scale(1.5) rotate(-15deg);   }
                    &:nth-child(3)::after { transform: scale(1.5) rotate(-17.5deg); }
                    &:nth-child(4)::after { transform: scale(1.5) rotate(17.5deg);  }
                    &:nth-child(5)::after { transform: scale(1.5) rotate(25deg);    }
                    &:nth-child(6)::after { transform: scale(1.5) rotate(-17.5deg); }
                }
                
                &__play-button {
                    display: inline-block;
                    z-index: 2;
                    width: 30%;
                    height: 30%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .video-dialog {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
        animation: fadeIn .3s ease-in-out;

        .dialog__content {
            background: #000000;
            animation: fadeIn .5s ease-in-out .3s 1 normal forwards;
            opacity: 0;

            @media screen and (min-width: 1201px) {
                width: 75vw;
                height: calc(9 / 16 * 75vw);
            }

            @media screen and (max-width: 1200px) {
                width: 90vw;
                height: calc(9 / 16 * 90vw);
            }

            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }

    @keyframes fadeInSlideUp {
        from { opacity: 0; transform: translateY(25px); }
        to   { opacity: 1; transform: translateY(0); }
    }

    @keyframes fadeIn {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
</style>

<script>

export default {
    name: 'LckvVideos',

    mounted() {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animation');
                }
            });
        });
        
        observer.observe(document.querySelector('.video__section .section__title'));
        observer.observe(document.querySelector('.video__section .section__text'));
        observer.observe(document.querySelector('.video__section .video__grid'));
    },

    data() {
        return {
            dialog: null,
        }
    },

    methods: {
        openPlayerDialog(video) {
            console.log(video);
            let videos = {
                'spellen': 'M3GFod_2Jx4',
                'zwemmen': 'XiG4iE7yqAQ',
                'kampvuur': '37Ap56KJ8xE',
            };

            this.dialog = videos[video];

            if (window.history && window.history.pushState) {
                window.history.pushState('forward', null, './#modal');

                window.addEventListener('popstate', this.closePlayerDialog);
                window.addEventListener('keydown', this.listenForEscape);
            }
        },

        closePlayerDialog() {
            this.dialog = null;
            window.history.pushState('forward', null, './#video');
            window.removeEventListener('popstate', this.closePlayerDialog);
            window.removeEventListener('keydown', this.listenForEscape);
        },

        listenForEscape(event) {
            if (event.code === 'Escape') {
                this.closePlayerDialog();
            }
        }
    }
};
</script>
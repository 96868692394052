<template>
    <div>
        <lckv-header :site-status="siteStatus" :before-draw="beforeDraw" />
        <main>
            <lckv-videos />
            <lckv-about :site-status="siteStatus" />
            <lckv-check-available :site-status="siteStatus" :before-draw="beforeDraw" />
        </main>
        <lckv-footer />
    </div>
</template>

<script>
import LckvAbout from './components/Lckv-About.vue'
import LckvFooter from './components/Lckv-Footer.vue'
import LckvHeader from './components/Lckv-Header.vue'
import LckvVideos from './components/Lckv-Videos.vue'
import LckvCheckAvailable from './components/Lckv-CheckAvailable.vue'

export default {
    name: 'App',
    components: {
        LckvHeader,
        LckvVideos,
        LckvAbout,
        LckvFooter,
        LckvCheckAvailable,
    },

    data() {
        return {
            siteStatus: 'Open',
            beforeDraw: true,
        }
    },

    mounted() {
        this.getSiteStatus();
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (entry.target.id === 'header') {
                        window.history.pushState('forward', null, `./`);    
                    } else {
                        window.history.pushState('forward', null, `./#${entry.target.id}`);
                    }
                }
            });
        }, { rootMargin: '-50px' });
        
        observer.observe(document.querySelector('#header'));
        observer.observe(document.querySelector('#video'));
        observer.observe(document.querySelector('#about'));
        observer.observe(document.querySelector('#camps'));
    },

    methods: {
        async getSiteStatus()  {
            try {
                const response  = await this.$axios.post('https://sfeerimpressie.lckv.nl/api/status.php');
                this.siteStatus = response.data.SiteDeelnemersStatus;
                let drawDate    = new Date(response.data.SiteDeelnemersDatumLoting);
                let now         = new Date();
                this.beforeDraw = drawDate.getTime() - now.getTime() > 0;
            } catch(e) {
                console.error("Can't retrieve portal status");
            }
        }
    }
}
</script>

<style lang="scss">


@font-face {
    font-family: 'Gentona';
    src: url("./assets/fonts/light.eot");
    src: url("./assets/fonts/light.eot?#iefix") format("embedded-opentype"), 
         url("./assets/fonts/light.woff") format("woff"), 
         url("./assets/fonts/light.ttf") format("truetype");
        //  url("./assets/fonts/light.svg#light") format("svg");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gentona';
    src: url("./assets/fonts/regular.eot");
    src: url("./assets/fonts/regular.eot?#iefix") format("embedded-opentype"), 
         url("./assets/fonts/regular.woff") format("woff"), 
         url("./assets/fonts/regular.ttf") format("truetype");
        //  url("./assets/fonts/regular.svg#regular") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gentona';
    src: url("./assets/fonts/bold.eot");
    src: url("./assets/fonts/bold.eot?#iefix") format("embedded-opentype"), 
         url("./assets/fonts/bold.woff") format("woff"), 
         url("./assets/fonts/bold.ttf") format("truetype");
        //  url("./assets/fonts/bold.svg#bold") format("svg");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gentona';
    src: url("./assets/fonts/italic.eot");
    src: url("./assets/fonts/italic.eot?#iefix") format("embedded-opentype"), 
         url("./assets/fonts/italic.woff") format("woff"), 
         url("./assets/fonts/italic.ttf") format("truetype");
        //  url("./assets/fonts/italic.svg#italic") format("svg");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gentona';
    src: url("./assets/fonts/bolditalic.eot");
    src: url("./assets/fonts/bolditalic.eot?#iefix") format("embedded-opentype"), 
         url("./assets/fonts/bolditalic.woff") format("woff"), 
         url("./assets/fonts/bolditalic.ttf") format("truetype");
        //  url("./assets/fonts/bolditalic.svg#bolditalic") format("svg");
    font-weight: bold;
    font-style: italic;
}

b, strong {
    font-weight: bold;
}

i {
    font-style: italic;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/*********** end reset ***********/

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Gentona',sans-serif;

    @media screen and (min-width: 1201px) {
        font-size: 16px;
    }
    @media screen and (min-width: 769px) and (max-width: 1200px){
        font-size: 12px;
    }
    @media screen and (max-width: 768px) {
        font-size: 10px;
    }
}

a {
    color: inherit;
    text-decoration: underline;

    &:hover {
        color: #f53c05;
    }
}

main {
    width: 100%;
    min-height: 100vh;
    background: #faf8f6;

    .page__section {
        min-height: 100vh;
        padding: 100px 0;
        box-sizing: border-box;
        width: 100%;
        // max-width: 1200px;
        margin: auto;
        
        &:nth-child(odd) {
            background-color: #faf8f6;
        }
        
        &:nth-child(even) {
            background-color: #ffffff;
        }
    }

    .section {
        &__title {
            font-size: 4.5em;
            color: #f53c05;
            font-weight: bold;
            text-align: center;
        }

        &__text {
            font-size: 2em;
            color: #323232;
            text-align: center;
            max-width: 75%;
            margin: auto;
            line-height: 1.25em;
        }
    }
}

.buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    gap: 1.5em;
    padding: 4em;
    box-sizing: border-box;

    @media screen and (min-width: 769px) {
        flex-direction: row;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    .button {
        background: none;
        border: 4px solid #fff;
        padding: 10px 20px;
        color: #fff;
        font-size: 2em;
        font-family: 'Gentona',sans-serif;
        font-weight: 600;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        margin-bottom: 10px;
        line-height: 1.5em;

        &:hover {
            text-decoration: underline;
        }
    }
}
</style>

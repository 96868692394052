<template>
    <section class="check-available__section" id="camps">
        <div class="page__section">
            <h2 class="section__title">Inschrijven</h2>
            <div class="section__text" style="margin: 0 auto;">
                <template v-if="beforeDraw">
                    Wil je direct weten wanneer jij de leukste week van het jaar kan beleven? Vul dan hier je leeftijd in en bekijk voor welke kampen jij je kan inschrijven!
                </template>
                <template v-else>
                    Wil je direct weten wanneer jij de leukste week van het jaar kan beleven? Vul dan hier je gegevens in en bekijk welke kampen nog beschikbaar zijn!
                </template>
            </div>
            <div class="form">
                <div class="form__age">
                    <label class="form__label" for="age">Leeftijd:</label>
                    <input type='number' v-model="age" class="form__input input__age" id="age">
                </div>
                <div v-if="! beforeDraw" class="form__gender">
                    <label class="form__label">Geslacht:</label>
                    <div class="form__input">
                        <label class="input__container">
                            <input type='radio' v-model="gender" value='M' name='gender'>Jongen
                            <span class="checkmark"></span>
                        </label>
                        <label class="input__container">
                            <input type='radio' v-model="gender" value='V' name='gender'>Meisje
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="availableSpots" class="camp__list-wrapper">
            <div v-if="availableSpots.length > 0" class="camp__list">
                <lckv-camp 
                    v-for="camp of availableSpots" 
                    :key="camp.id" 
                    :value="camp"
                    :site-status="siteStatus"
                    class="camp__item"
                />
            </div>
            <div v-else class="section__text">Helaas, Er zijn geen plekken beschikbaar voor jouw leeftijd</div>
        </div>
    </section>
</template>


<style lang="scss" scoped>
    .page__section {
        padding: 4em 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background: none !important;
    }

    .check-available {
        &__section {
            background-image: url('../assets/images/tent.svg');
            background-repeat: no-repeat;
            background-attachment: fixed;
            
            @media screen and (min-width: 769px) {
                background-position: right -50vh bottom -5vh;
                background-size: 120vh;
            }
            @media screen and (max-width: 768px) {
                background-position: right -35vh bottom 0vh;
                background-size: 80vh;
            }
        }
    }

    .input {
        &__age {
            border-width: 0 0 1px 0;
            border-color: #72665a;
            border-style: solid;
            background: transparent;
            width: 2em;
            text-align: center;
            outline: none;

            color: #72665a;
            font-size: 3em;
            font-family: 'Gentona',sans-serif;
            font-weight: bold;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    .camp {
        &__list-wrapper {
            width: 75%;
            margin: auto;
            padding-bottom: 4em;

            @media screen and (min-width: 1201px) {
                width: 75%;
            }

            @media screen and (max-width: 1200px) {
                width: 100%;
            }
        }
        
        &__list {
            display: grid;
            gap: 2em;
            width: 100%;
            max-width: 90vw;
            margin: auto;

            @media screen and (min-width: 1201px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media screen and (min-width: 1025px) and (max-width: 1200px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media screen and (min-width: 769px) and (max-width: 1024px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }

    .form {
        width: 35em;
        &__age,
        &__gender {
            display: flex;
            width: 100%;
            justify-content: space-around;
            margin-bottom: 2em;
        }
        &__age {
            align-items: center;
        }
        &__label {
            font-size: 2.5em;
            font-weight: bold;
            width: 5em;
            text-align: right;
        }
        &__input {
            width: 140px;
        }
    }

    .input__container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;

            &:checked ~ .checkmark:after {
                display: block;
            }
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #72665a;
            border-radius: 50%;
        
            &::after {
                content: "";
                position: absolute;
                display: none;
                top: 9px;
                left: 9px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: white;
            }
        }
    }
</style>

<script>

import LckvCamp from './Lckv-Camp';

export default {
    name: 'LckvCheckAvailable',

    components: { LckvCamp },

    props: {
        siteStatus: { type: String, default: 'Open' },
        beforeDraw: { type: Boolean, default: true },
    },

    data() {
        return {
            age:    null,
            gender: null,
            schema: null,
        }
    },

    async mounted() {
        const response = await this.$axios.get('https://sfeerimpressie.lckv.nl/api/kampschema.php');
        this.schema = response.data;
    },

    computed: {
        availableSpots() {
            if (! this.schema || ! this.age || (! this.gender && ! this.beforeDraw)) return null;

            let spots = this.schema.filter((camp) => {
                if (camp.KampTypeID === "1103") return false;

                if (parseInt(camp.LeeftijdTot) >= this.age && parseInt(camp.LeeftijdVan) <= this.age) {
                    if (this.beforeDraw) {
                        return true;
                    }
                    if (this.gender === 'M') {
                        if (parseInt(camp.BeschikbaarJongens) > 0) {
                            return true;
                        }
                    }
                    if (this.gender === 'V') {
                        if (parseInt(camp.BeschikbaarMeisjes) > 0) {
                            return true;
                        }
                    }
                }
            });

            spots.sort((a, b) => {
                let aAvailable = parseInt(a.BeschikbaarJongens) + parseInt(a.BeschikbaarMeisjes);
                let bAvailable = parseInt(b.BeschikbaarJongens) + parseInt(b.BeschikbaarMeisjes);

                if (aAvailable < bAvailable) return 1;
                if (aAvailable > bAvailable) return -1;

                if (parseInt(a.Nummer) === parseInt(b.Nummer)) return 0;
                
                if (parseInt(a.Nummer) < parseInt(b.Nummer)) return -1;
                if (parseInt(a.Nummer) > parseInt(b.Nummer)) return 1;
            });

            return spots;
        }
    }
}
</script>